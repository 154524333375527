import React, { useRef, useState, useEffect } from 'react';

function Footer() {
    const footerRef = useRef(null);
    useEffect(() => {
        function handleResize() {
            const pageHeight = document.body.offsetHeight;
            const windowHeight = window.innerHeight;
            const difference = pageHeight - windowHeight;
            if (footerRef.current) {
                if (pageHeight < 600) {
                    footerRef.current.style.marginBottom = "700px";
                } else {
                    footerRef.current.style.marginBottom = `-${difference}px`;
                }
            }
        }
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    
    return (
        <div>

            <footer ref={footerRef} className='d-flex flex-column flex-grow-1 bg-green '>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm">
                            <h5 >HERFONTSISTEMAS</h5>
                            <p>Todos los derechos reservados</p>
                            <p><a href='#'>Política de privacidad</a></p>
                        </div>
                        <div class="col-sm">
                            <h5>Contacto</h5>
                            <p>flleras@hotmail.com</p>
                            <p>teléfono: (+34) 660 86 00 25</p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>


    )
}

export default Footer
