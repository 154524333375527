import React from 'react'
import puntosfijos from "../img/puntosfijos.JPG"
function Puntosfijos() {
    return (
        <div className='container mt-4 parent-container'>
            < h2>PUNTOS FIJOS</h2>
            <p><img src={puntosfijos}></img></p>
        </div>
    )
}

export default Puntosfijos
