import React from 'react'
import accesoriostrabajo from "../img/accesoriostrabajo.JPG"
import accesoriotrabajo2 from "../img/accesoriotrabajo2.JPG"
import accesoriotrabajo3 from "../img/accesoriotrabajo3.JPG"

function Accesoriostrabajo() {
  return (
    <div className='container mt-4 parent-container'>
      <h2>ACCESORIOS DE TRABAJO</h2>
      <p><img src={accesoriostrabajo}></img></p>
      <br></br><br></br>
      <p><img src={accesoriotrabajo2}></img></p>
      <br></br><br></br>
      <p><img src={accesoriotrabajo3}></img></p>
    </div>
  )
}

export default Accesoriostrabajo
