import React from 'react'
import anclaje from "../img/anclaje.JPG"
import anclaje2 from "../img/anclaje2.JPG"
function Anclajes() {
  return (
    <div>
      <div className='container mt-4 parent-container'>
            < h2>ANCLAJES</h2>
            <p><img src={anclaje}></img></p>
            <br></br><br></br>
            <p><img src={anclaje2}></img></p>

        </div>
    </div>
  )
}

export default Anclajes
