import React from 'react'
import sistemasmontajeventilacion from "../img/sistemasmontajeventilacion.JPG"
function Sistemasmontajeventilacion() {
    return (
        <div className='container mt-4 parent-container'>
            < h2>SISTEMAS DE MONTAJE PARA LA VENTILACIÓN</h2>
            <p><img src={sistemasmontajeventilacion}></img></p>

        </div>
    )
}

export default Sistemasmontajeventilacion
