import React from 'react'
import { useState } from 'react'
import { Router, Route, Switch, Link, Navigate } from 'react-router-dom';
import obraServicios from "./img/obraServicios.jpg"
import "../App.css";
function Servicios() {
    return (
        <div className='container text-center'>
            <h2 className="text-shadow-sm">Conoce nuestros servicios</h2>
            <br></br>
            <div className='row align-items-start parent-container'>
                <div class="col padding">
                    <ul className='list-unstyled'>
                        <li className='bigger-on-hover my-link-verde'><Link to="/Servicios/GamaCompleta">GAMA COMPLETA DE PRODUCTOS</Link></li>
                        <li className='bigger-on-hover my-link-verde'><Link to="/Servicios/Soluciones">SOLUCIONES ESPECIALES ÚNICAS</Link></li>
                        <li className='bigger-on-hover my-link-verde'><Link to="/Servicios/Apoyo">APOYO A LA PLANIFICACIÓN</Link></li>
                        <li className='bigger-on-hover my-link-verde'><Link to="/Servicios/Premontaje">PREMONTAJE QUE AHORRA TIEMPO</Link></li>
                        <li className='bigger-on-hover my-link-verde'><Link to="/Servicios/Logistica">LOGÍSTICA FIABLE</Link></li>
                    </ul>
                </div>
                <div class="col ">
                    <img src={obraServicios} className="shadow-lg p-3 mb-5 bg-white rounded" width="700" height="550"></img>
                </div>

            </div>
        </div>


    )

}

export default Servicios