import React, {useState}from 'react';
import { faCheck, faTimes, faInfoCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BrowserRouter as Route, Link } from 'react-router-dom';

export default function ListarCarrito() {
  const [listaCarrito, setListaCarrito] = useState(JSON.parse(localStorage.getItem("listaCarrito")) || []);

  const eliminarCesta = (producto) => {
    const newListaCarrito = listaCarrito.filter((x) => x.id !== producto.id);
    setListaCarrito(newListaCarrito);
    localStorage.setItem("listaCarrito", JSON.stringify(newListaCarrito));
  }
  const precioProducto = listaCarrito.reduce((a, c) => a + c.precio * c.cantidad, 0);
  //const precioEnvio = precioProducto > 2000 ? 0 : 20;
  const precioTotal = precioProducto; //+ precioEnvio;
  return (
    <aside className="block col-8 parent-container">
      <h2>Carrito</h2>
      <div id='divCarrito '>
        {listaCarrito.length === 0 && <div>Carrito vacío</div>}
        {listaCarrito.map((producto) => (
          <div key={producto.id} className="row">
            <div className="col-2">{producto.nombre}</div>
            <div className="col-2 text-right">
              {producto.cantidad} x €{producto.precio.toFixed(2)}
              <button onClick={() => eliminarCesta(producto)} className="remove">
              <FontAwesomeIcon icon={faTrash}/>
              </button>{' '}
            </div>
          </div>
        ))}

        {listaCarrito.length !== 0 && (
          <>
            <hr></hr>
            {/* <div className="row">
              <div className="col-2">Precio Envio</div>
              <div className="col-1 text-right">
                {precioEnvio.toFixed(2)}€
              </div>
            </div> */}

            <div className="row">
              <div className="col-2">
                <strong>Precio Total</strong>
              </div>
              <div className="col-1 text-right">
                <strong>{precioTotal.toFixed(2)}€</strong>
              </div>
            </div>
            <hr />
            <div className="row">
              <p>El pago se hará al entregar los productos.</p>
              <Link to="/FinalizarCompra" className="btn btn-primary">Finalizar Compra</Link>
            </div>
          </>
        )}
      </div>
    </aside>
  );
}