import React, { useRef, useState, useEffect } from 'react';
import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import APIService from '../APIService';
import { rutaMaquina } from '../Rutas';
import Swal from 'sweetalert2';
function FormProductos(props) {

    const expresion_nombre = /^(?=.{3,20})[A-ZÑÁÉÍÓÚÜü][a-zñáéíóúü]+((\s|-)[A-ZÑÁÉÍÓÚÜ]?[a-zñáéíóúü]+)*$/;
    const expresion_descripcion = /^[a-zñáéíóúüA-ZÑÁÉÍÓÚÜ0-9(\s)@$%/¡!.,=?¿+_-ºª&""'']+$/;
    const expresion_cantidad = /^[0-9]+$/;
    const expresion_imagenes = /\.(jpeg|jpg|png|JPEG|JPG|PNG)$/;

    const [nom_producto, setNombre] = useState('')
    const [validNombre, setValidNombre] = useState(false)
    const [focusNombre, setFocusNombre] = useState(false)

    const [descripcion, setDescripcion] = useState('')
    const [validDescripcion, setValidDescripcion] = useState(false)

    const [cantidad, setCantidad] = useState('')
    const [validCantidad, setValidCantidad] = useState(false)
    const [focusCantidad, setFocusCantidad] = useState(false)

    const [imagen, setImagen] = useState('')
    const [validImagen, setValidImagen] = useState(false)
    const [focusImagen, setFocusImagen] = useState(false)

    const [selectedFile, setSelectedFile] = useState(null);



    const [precio, setPrecio] = useState('')
    const [validPrecio, setValidPrecio] = useState(false)
    const [focusPrecio, setFocusPrecio] = useState(false)

    const [currentImage, setCurrentImage] = useState('')

    const nombreRef = useRef();
    const descripcionRef = useRef();
    const cantidadRef = useRef();
    const imagenRef = useRef();
    const precioRef = useRef();

    useEffect(() => {
        const result = expresion_nombre.test(nom_producto);
        setValidNombre(result);
    }, [nom_producto])

    useEffect(() => {
        const result = expresion_descripcion.test(descripcion);
        setValidDescripcion(result);
    }, [descripcion])

    useEffect(() => {
        const result = expresion_cantidad.test(cantidad);
        setValidCantidad(result);
    }, [cantidad])

    useEffect(() => {
        const result = expresion_imagenes.test(imagen);
        setValidImagen(result);
    }, [imagen])

    useEffect(() => {
        const result = expresion_cantidad.test(precio);
        setValidPrecio(result);
    }, [precio])

    useEffect(() => {
        setNombre(props.producto.nom_producto)
        setDescripcion(props.producto.descripcion)
        setCantidad(props.producto.cantidad)
        setImagen(props.producto.imagen)
        setPrecio(props.producto.precio)
        if (props.producto) setCurrentImage(props.producto.imagen)
    }, [props.producto])

    const handleFileChange = (event) => {
        console.log(event.target.files[0].name)
        if (event.target.files[0].size > 5000000 || !event.target.files[0].name.match(/\.(jpg|jpeg|png|JPEG|JPG|PNG)$/)) {
            alert('Imagen demasiado grande o tiene extension png jgp o jpeg')
        } else {
            setSelectedFile(event.target.files[0]);
        }

    }

    async function insertarProducto(event) {
        event.preventDefault();
        if (selectedFile) {
            if (expresion_nombre.test(nom_producto) && expresion_descripcion.test(descripcion) && expresion_cantidad.test(cantidad) && expresion_cantidad.test(precio) && expresion_imagenes.test(selectedFile.name)) {
                console.log("hola")
                const formData = new FormData();
                formData.append("nom_producto", nom_producto);
                formData.append("descripcion", descripcion);
                formData.append("cantidad", cantidad);
                formData.append("precio", precio)
                formData.append("image", selectedFile);
                fetch(rutaMaquina + "/herfontsistemas-back/nuevoProducto", {
                    method: 'POST',
                    body: formData
                })
                    .then(async (response) => {
                        const { value: accept } = await Swal.fire({
                            title: "¡PRODUCTO INSERTADO!",
                            text: "El producto ha sido insertado correctamente.",
                            icon: "success",
                        });
                        if (accept) {
                            window.location.reload(true);
                        }
                    })

                    .catch(error => {
                        console.error(error);
                    });
            } else {
                alert('El producto no se pudo insertar, revise el formulario')
            }
        } else {
            alert('El producto no se pudo insertar, revise el formulario')
        }
    }


    async function modificarProducto (event) {
        event.preventDefault();
        if (selectedFile == null) {
            if (expresion_nombre.test(nom_producto) && expresion_descripcion.test(descripcion) && expresion_cantidad.test(cantidad) && expresion_cantidad.test(precio) && expresion_imagenes.test(currentImage)) {
                console.log("hola3")
                const formData = new FormData();
                formData.append("nom_producto", nom_producto);
                formData.append("descripcion", descripcion);
                formData.append("cantidad", cantidad);
                formData.append("precio", precio)
                console.log(currentImage)
                formData.append("image", currentImage);
                fetch(rutaMaquina + "/herfontsistemas-back/modificarProducto/" + props.producto.id, {
                    method: 'PUT',
                    body: formData
                })
                    .then(async (response) => {
                        const { value: accept } = await Swal.fire({
                            title: "¡PRODUCTO MODIFICADO!",
                            text: "El producto ha sido modificado correctamente.",
                            icon: "success",
                        });
                        if (accept) {
                            window.location.reload(true);
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
            } else {
                alert('El producto no se pudo insertar, revise el formulario')
            }
        } else {
            console.log(expresion_imagenes.test(selectedFile))
            console.log(expresion_nombre.test(nom_producto))
            if (expresion_nombre.test(nom_producto) && expresion_descripcion.test(descripcion) && expresion_cantidad.test(cantidad) && expresion_cantidad.test(precio) && !expresion_imagenes.test(selectedFile)) {
                console.log("hola3")
                const formData = new FormData();
                formData.append("nom_producto", nom_producto);
                formData.append("descripcion", descripcion);
                formData.append("cantidad", cantidad);
                formData.append("precio", precio)
                formData.append("image", selectedFile);
                fetch(rutaMaquina + "/herfontsistemas-back/modificarProducto/" + props.producto.id, {
                    method: 'PUT',
                    body: formData
                })
                    .then(async (response) => {
                        const { value: accept } = await Swal.fire({
                            title: "¡PRODUCTO MODIFICADO!",
                            text: "El producto ha sido modificado correctamente.",
                            icon: "success",
                        });
                        if (accept) {
                            window.location.reload(true);
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
            } else {
                alert('El producto no se pudo insertar, revise el formulario')
            }
        }
    }

    return (
        <div>
            {props.producto ? (
                <div className="mb-3">
                    <h1>Datos Producto</h1>
                    <form onSubmit={props.producto.id ? modificarProducto : insertarProducto} enctype="multipart/form-data">
                        <label htmlFor='nom_producto' className='form-label'>Nombre
                            <span className={validNombre ? "valid" : "hide"}>
                                <FontAwesomeIcon icon={faCheck} />
                            </span>
                            <span className={validNombre || !nom_producto ? "hide" : "invalid"}>
                                <FontAwesomeIcon icon={faTimes} />
                            </span>
                        </label>
                        <input type="text" name="nom_producto" ref={nombreRef} autoComplete="off" className='form-control'
                            placeholder="Ingrese el nombre"
                            value={nom_producto}
                            onChange={(u) => setNombre(u.target.value)}
                            required
                            aria-invalid={validNombre ? "false" : "true"}
                            aria-describedby="nomnote"
                            onFocus={() => setFocusNombre(true)}
                            onBlur={() => setFocusNombre(false)}
                        />
                        <label htmlFor='descripcion' className='form-label'>Descripcion
                            <span className={validDescripcion ? "valid" : "hide"}>
                                <FontAwesomeIcon icon={faCheck} />
                            </span>
                            <span className={validDescripcion || !descripcion ? "hide" : "invalid"}>
                                <FontAwesomeIcon icon={faTimes} />
                            </span></label>
                        <textarea rows={6} cols={18} type="text" className='form-control'
                            placeholder="Ingrese el mensaje"
                            value={descripcion}
                            onChange={(u) => setDescripcion(u.target.value)}
                            name='descripcion' ref={descripcionRef} autoComplete="off"
                            required
                            aria-invalid={validDescripcion ? "false" : "true"} />

                        <label htmlFor='cantidad' className='form-label'>Cantidad
                            <span className={validCantidad ? "valid" : "hide"}>
                                <FontAwesomeIcon icon={faCheck} />
                            </span>
                            <span className={validCantidad || !cantidad ? "hide" : "invalid"}>
                                <FontAwesomeIcon icon={faTimes} />
                            </span>
                        </label>
                        <input type="text" name='cantidad' ref={cantidadRef} autoComplete="off" className='form-control'
                            placeholder="Ingrese la cantidad"
                            value={cantidad}
                            onChange={(u) => setCantidad(u.target.value)}
                            required
                            aria-invalid={validCantidad ? "false" : "true"}
                            aria-describedby="nomnote"
                            onFocus={() => setFocusCantidad(true)}
                            onBlur={() => setFocusCantidad(false)}
                        />
                        <label htmlFor='imagen' className='form-label'>Imagen
                            <span className={validImagen ? "valid" : "hide"}>
                                <FontAwesomeIcon icon={faCheck} />
                            </span>
                            <span className={validImagen || !imagen ? "hide" : "invalid"}>
                                <FontAwesomeIcon icon={faTimes} />
                            </span>
                        </label>
                        <img src={rutaMaquina + '/herfontsistemas-back/static/img/' + currentImage} alt="Loading..." className="mb-3" style={{ width: '100px' }} />
                        <input type="file" name="imagen" ref={imagenRef} autoComplete="off" className='form-control'
                            placeholder="Ingrese imagen"
                            onChange={handleFileChange}
                            aria-invalid={validImagen ? "false" : "true"}
                            aria-describedby="nomnote"
                            onFocus={() => setFocusImagen(true)}
                            onBlur={() => setFocusImagen(false)}
                        />

                        <label htmlFor='precio' className='form-label'>Precio
                            <span className={validPrecio ? "valid" : "hide"}>
                                <FontAwesomeIcon icon={faCheck} />
                            </span>
                            <span className={validPrecio || !precio ? "hide" : "invalid"}>
                                <FontAwesomeIcon icon={faTimes} />
                            </span>
                        </label>
                        <input type="text" ref={precioRef} autoComplete="off" className='form-control'
                            placeholder="Ingrese precio"
                            value={precio}
                            onChange={(u) => setPrecio(u.target.value)}
                            required
                            aria-invalid={validCantidad ? "false" : "true"}
                            aria-describedby="precionote"
                            onFocus={() => setFocusPrecio(true)}
                            onBlur={() => setFocusPrecio(false)}
                        />
                        <button className='btn btn-success mt3'>Aceptar</button>
                        {/* {
                            props.producto.id ? <button onClick={modificarProducto} className='btn btn-success mt3'>Modificar</button>
                            :
                            <button className='btn btn-success mt3'>Insertar</button>

                        } */}
                    </form>

                </div>
            ) : null}
        </div>
    )
}
export default FormProductos