import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import logo from './img/logo.jpg';
import fotohom from './img/fotohom.JPG';
import obra from './img/obra.JPG';
import obra2 from './img/obra2.JPG';
import tuberiasHome from "./img/tuberiasHome.jpg";
import { Mapa } from './Mapa';


function Home() {
  return (
    <div className='parent-container'>
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block imgCarrusel "
            src={fotohom}
            alt="First slide"
          />
          {/* <Carousel.Caption>
            <h3>First slide label</h3>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block imgCarrusel"
            src={tuberiasHome}
            alt="Second slide"
          />

          {/* <Carousel.Caption>
            <h3>Second slide label</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block imgCarrusel"
            src={obra2}
            alt="Third slide"
          />
{/* 
          <Carousel.Caption>
            <h3>Third slide label</h3>
            <p>
              Praesent commodo cursus magna, vel scelerisque nisl consectetur.
            </p>
          </Carousel.Caption> */}
        </Carousel.Item>
      </Carousel>
      <br></br>
      <div className="container">
      <h2>Encuentranos en</h2>
        <p>C/ del Estanque, 5. P.I. La Fontana, nave n° 3. </p>
        <p>28945 Fuenlabrada Madrid</p>


      </div>
      <div className='container'>
        
        <Mapa />
      </div>
    </div>


  );
}

export default Home