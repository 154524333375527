import React from 'react'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'

function Apoyo() {
    return (
        <div className='container parent-container'>
            <div class="col-4">
                <button type="button" className="btn btn-success my-link btn-sm  col-sm-2"><Link to="/Servicios"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-return-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"/>
</svg></Link></button>
            </div>
            <div class="row justify-content-md-center parent-container">
            
                <div class="col-sm-8">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">APOYO A LA PLANIFICACIÓN</h5>
                            <p class="card-text">Nuestros especialistas brindan soporte para todos los servicios de planificación.</p>
                            <ul className='milista'>
                                <li >Registro de todos los datos y desarrollo de soluciones técnicas, también in situ</li>
                                <li>Planificación de rutas de tuberías y ventilación, cálculo de punto fijo</li>
                                <li>Verificaciones estáticas, listas de piezas y dibujos</li>
                            </ul>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Apoyo
