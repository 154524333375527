import React from 'react'
import aceroInoxidable from "../img/aceroInoxidable.JPG"
function AceroInoxidable() {
  return (
    <div className='container mt-4 parent-container'>
      < h2>ACERO INOXIDABLE</h2>
      <p><img src={aceroInoxidable}></img></p>
    </div>
  )
}

export default AceroInoxidable
