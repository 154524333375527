import React from 'react'
import aisladoresruido from "../img/aisladoresruido.JPG"
function Aisladoresruido() {
    return (
        <div className='container mt-4 parent-container'>
            < h2>AISLADORES DE RUIDO</h2>
            <p><img src={aisladoresruido}></img></p>
        </div>
    )
}

export default Aisladoresruido
