import React from 'react'
import sprinklers from "../img/sprinklers.JPG"
import spinklers2 from "../img/spinklers2.JPG"

function Sprinklers() {
    return (
        <div className='container mt-4 parent-container'>
            < h2>SISTEMAS CONTRA INCENDIOS</h2>
            <p><img src={sprinklers}></img></p>
            <br></br><br></br>
            <p><img src={spinklers2}></img></p>
        </div>
    )
}

export default Sprinklers
