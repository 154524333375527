import React, { useRef, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser'
import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Swal from 'sweetalert2';
import APIService from './APIService';
import Productos from './Productos';

const FinalizarCompra = () => {
    const [listaCarrito, setListaCarrito] = useState(JSON.parse(localStorage.getItem("listaCarrito")) || []);

    const NOMBRE_VAL = /^(?=.{3,20})[A-ZÑÁÉÍÓÚ][a-zñáéíóú]+((\s|-)[A-ZÑÁÉÍÓÚ][a-zñáéíóú]+)*$/;
    const APE_VAL = /^(?=.{3,30})[A-ZÑÁÉÍÓÚ][a-zñáéíóú]+((\s|-)[A-ZÑÁÉÍÓÚ][a-zñáéíóú]+)*$/;
    const CORREO_VAL = /^[\w\.\_]{3,5}\+?[\w\.\_]{0,20}@[\w]{3,}\.\w{2,5}$/;
    const TEL_VAL = /^[0-9]{9}$/;
    const DIR_VAL = /^[A-ZÑÁÉÍÓÚ][a-zñáéíóú]+((\s|-)[A-ZÑÁÉÍÓÚa-zñáéíóúº]+(\s|-)*[0-9]*)*$/;
    const CIUPROV_VAL = /^(?=.{3,30})[A-ZÑÁÉÍÓÚ][a-zñáéíóú]+((\s|-)[A-ZÑÁÉÍÓÚ][a-zñáéíóú]+)*$/;
    const CODPOST_VAL = /^[0-9]{1,5}$/;
    const MENSAJE_VAL = /^[a-zñáéíóúüA-ZÑÁÉÍÓÚÜ0-9(\s)@$%/¡!.,=?¿+_-ºª&""'']+$/;

    const nombreRef = useRef();
    const apellidosRef = useRef();
    const correoRef = useRef();
    const direccionRef = useRef();
    const telefonoRef = useRef();
    const ciudadRef = useRef();
    const provinciaRef = useRef();
    const codigoPostalRef = useRef()
    const mensajeRef = useRef();

    const [nombre, setNombre] = useState('')
    const [validNombre, setValidNombre] = useState('')
    const [nombreFocus, setNombreFocus] = useState(false)

    const [apellidos, setApellidos] = useState('')
    const [validApe, setValidApellido] = useState('')
    const [apellidoFocus, setApellidoFocus] = useState(false)

    const [correo, setCorreo] = useState('')
    const [validCorreo, setValidCorreo] = useState('')

    const [direccion, setDireccion] = useState('')
    const [validDireccion, setValidDireccion] = useState('')
    const [focusDireccion, setFocusDireccion] = useState('')

    const [telefono, setTelefono] = useState('')
    const [validTel, setValidTelefono] = useState('')
    const [focusTel, setFocusTelefono] = useState(false)


    const [ciudad, setCiudad] = useState('')
    const [validCiudad, setValidCiudad] = useState('')
    const [focusCiudad, setFocusCiudad] = useState(false)

    const [provincia, setProvincia] = useState('')
    const [validProvincia, setValidProvincia] = useState('')
    const [focusProvincia, setFocusProvincia] = useState(false)

    const [codigo_postal, setCodigoPostal] = useState('')
    const [validCodigo, setValidCodigo] = useState('')
    const [focusCodigo, setFocusCodigo] = useState(false)

    const [mensaje, setMensaje] = useState('')
    const [validMensaje, setValidMensaje] = useState(false)

    useEffect(() => {
        const data = sessionStorage.getItem('nombre');
        if (!data) {
            window.location.href = '#/Login';
        }
    }, []);

    useEffect(() => {
        nombreRef.current.focus();
        apellidosRef.current.focus();
        correoRef.current.focus();
        direccionRef.current.focus();
        telefonoRef.current.focus();
        ciudadRef.current.focus();
        codigoPostalRef.current.focus()
        mensajeRef.current.focus();
    }, [])

    useEffect(() => {
        const result = NOMBRE_VAL.test(nombre);
        setValidNombre(result);
    }, [nombre])

    useEffect(() => {
        const result = APE_VAL.test(apellidos);
        setValidApellido(result);
    }, [apellidos])

    useEffect(() => {
        const result = CORREO_VAL.test(correo);
        setValidCorreo(result);
    }, [correo])

    useEffect(() => {
        const result = DIR_VAL.test(direccion);
        setValidDireccion(result);
    }, [direccion])

    useEffect(() => {
        const result = TEL_VAL.test(telefono);
        setValidTelefono(result);
    }, [telefono])

    useEffect(() => {
        const result = CIUPROV_VAL.test(ciudad);
        setValidCiudad(result);
    }, [ciudad])

    useEffect(() => {
        const result = CIUPROV_VAL.test(provincia);
        setValidProvincia(result);
    }, [provincia])

    useEffect(() => {
        const result = CODPOST_VAL.test(codigo_postal);
        setValidCodigo(result);

    }, [codigo_postal])

    useEffect(() => {
        const result = MENSAJE_VAL.test(mensaje);
        setValidMensaje(result);

    }, [mensaje])


    const sendEmail = (event) => {
        if (NOMBRE_VAL.test(nombre) && APE_VAL.test(apellidos) && CORREO_VAL.test(correo) && TEL_VAL.test(telefono) && DIR_VAL.test(direccion) && CIUPROV_VAL.test(ciudad) && CIUPROV_VAL.test(provincia) && CODPOST_VAL.test(codigo_postal) && MENSAJE_VAL.test(mensaje)) {
            localStorage.removeItem("listaCarrito")
            event.preventDefault();
            emailjs.sendForm('service_8rqjaee', 'template_qmslgkl', event.target, 'XDeCN6_0kxkgxNamr')
                .then(response => Swal.fire({
                    title: "¡ENVIADO CORRECTAMENTE!",
                    text: "Pronto le llegará un correo con la factura",
                    icon: "success",
                }))
                .catch(error => console.log(error))
            console.log(event.target)
        }
    }

    return (
        <div className='container'>
            <form className='title-form' onSubmit={sendEmail}>
                <h1>Sus productos:</h1>
                {listaCarrito.map((producto) => (
                    <div key={producto.id} className="row">
                        <div className="col-2">{producto.nombre}</div>
                        <div className="col-2 text-right">{producto.cantidad} x €{producto.precio.toFixed(2)}</div>
                        <input type='hidden' name='producto[]' value={`${producto.nombre} ${producto.cantidad} x ${producto.precio}`} />
                        <input type='hidden' name='nom_producto' value={producto.nombre} />
                        <input type='hidden' name='cant_producto' value={producto.cantidad} />
                        <input type='hidden' name='precio_producto' value={producto.precio}></input>
                    </div>
                ))}
                <h1>Rellene los Datos:</h1>
                <label htmlFor='nombre' className='form-label'>Nombre
                    <span className={validNombre ? "valid" : "hide"}>
                        <FontAwesomeIcon icon={faCheck} />
                    </span>
                    <span className={validNombre || !nombre ? "hide" : "invalid"}>
                        <FontAwesomeIcon icon={faTimes} />
                    </span>
                </label>
                <input type="text" id="nomUsuario" ref={nombreRef} autoComplete="off" className='form-control'
                    placeholder="Ingrese el nombre"
                    value={nombre}
                    onChange={(u) => setNombre(u.target.value)}
                    required
                    aria-invalid={validNombre ? "false" : "true"}
                    aria-describedby="nomnote"
                    onFocus={() => setNombreFocus(true)}
                    onBlur={() => setNombreFocus(false)}
                    name='nombre'
                />
                <p id="nomnote" className={nombreFocus && nombre && !validNombre ? "instructions" : "offscreen"}>
                    <FontAwesomeIcon icon={faInfoCircle} />
                    De 3 a 20 carácteres <br />
                    Debe empezar con una letra mayúscula<br />
                    Se permiten: letras, barra baja y guiones.
                </p>

                <label htmlFor='apellidos' className='form-label'>Apellidos
                    <span className={validApe ? "valid" : "hide"}>
                        <FontAwesomeIcon icon={faCheck} />
                    </span>
                    <span className={validApe || !apellidos ? "hide" : "invalid"}>
                        <FontAwesomeIcon icon={faTimes} />
                    </span>
                </label>
                <input type="text" className='form-control'
                    placeholder="Ingrese los apellidos"
                    value={apellidos}
                    onChange={(u) => setApellidos(u.target.value)}
                    id="apeUsuario" ref={apellidosRef} autoComplete="off"
                    required
                    aria-invalid={validApe ? "false" : "true"}
                    aria-describedby="apenote"
                    name='apellidos'
                    onFocus={() => setApellidoFocus(true)}
                    onBlur={() => setApellidoFocus(false)}
                />
                <p id="apenote" className={apellidoFocus && apellidos && !validApe ? "instructions" : "offscreen"}>
                    <FontAwesomeIcon icon={faInfoCircle} />
                    De 3 a 30 carácteres <br />
                    Debe empezar con una letra mayúscula<br />
                    Se permiten: letras, barra baja y guiones.
                </p>
                <label htmlFor='correo' className='form-label'>Correo
                    <span className={validCorreo ? "valid" : "hide"}>
                        <FontAwesomeIcon icon={faCheck} />
                    </span>
                    <span className={validCorreo || !correo ? "hide" : "invalid"}>
                        <FontAwesomeIcon icon={faTimes} />
                    </span>
                </label>
                <input type="text" className='form-control'
                    placeholder="Ingrese el correo"
                    value={correo}
                    onChange={(u) => setCorreo(u.target.value)}
                    id="correoUsuario" ref={correoRef} autoComplete="off"
                    required
                    name='correo'
                    aria-invalid={validCorreo ? "false" : "true"}
                />

                <label htmlFor='telefono' className='form-label'>Telefono
                    <span className={validTel ? "valid" : "hide"}>
                        <FontAwesomeIcon icon={faCheck} />
                    </span>
                    <span className={validTel || !telefono ? "hide" : "invalid"}>
                        <FontAwesomeIcon icon={faTimes} />
                    </span>
                </label>
                <input type="text" className='form-control'
                    placeholder="Ingrese el telefono"
                    value={telefono}
                    onChange={(u) => setTelefono(u.target.value)}
                    id="telUsuario" ref={telefonoRef} autoComplete="off"
                    required
                    aria-invalid={validTel ? "false" : "true"}
                    aria-describedby="telnote"
                    name='telefono'
                    onFocus={() => setFocusTelefono(true)}
                    onBlur={() => setFocusTelefono(false)}
                />
                <p id="telnote" className={focusTel && telefono && !validTel ? "instructions" : "offscreen"}>
                    <FontAwesomeIcon icon={faInfoCircle} />
                    Error, número incorrecto
                </p>

                <label htmlFor='direccion' className='form-label'>Direccion
                    <span className={validDireccion ? "valid" : "hide"}>
                        <FontAwesomeIcon icon={faCheck} />
                    </span>
                    <span className={validDireccion || !direccion ? "hide" : "invalid"}>
                        <FontAwesomeIcon icon={faTimes} />
                    </span>
                </label>
                <input type="text" className='form-control'
                    placeholder="Ingrese la direccion"
                    value={direccion}
                    name='direccion'
                    onChange={(u) => setDireccion(u.target.value)}
                    id="dirUsuario" ref={direccionRef} autoComplete="off"
                    required
                    aria-invalid={validDireccion ? "false" : "true"}
                    aria-describedby="dirnote"
                    onFocus={() => setFocusDireccion(true)}
                    onBlur={() => setFocusDireccion(false)}
                />
                <p id="dirnote" className={focusDireccion && direccion && !validDireccion ? "instructions" : "offscreen"}>
                    <FontAwesomeIcon icon={faInfoCircle} />
                    Debe introducir una dirección válida.<br />
                    La primera letra en mayúscula, puede contener espacios, números
                    y los siguientes caracteres especiales
                    <span aria-label="circulo arriba">º</span>
                    <span aria-label="guion">-</span>
                </p>
                <label htmlFor='ciudad' className='form-label'>Ciudad
                    <span className={validCiudad ? "valid" : "hide"}>
                        <FontAwesomeIcon icon={faCheck} />
                    </span>
                    <span className={validCiudad || !ciudad ? "hide" : "invalid"}>
                        <FontAwesomeIcon icon={faTimes} />
                    </span>
                </label>
                <input type="text" className='form-control'
                    placeholder="Ingrese la ciudad"
                    value={ciudad}
                    onChange={(u) => setCiudad(u.target.value)}
                    id="ciudadUsuario" ref={ciudadRef} autoComplete="off"
                    required
                    aria-invalid={validCiudad ? "false" : "true"}
                    name='ciudad'
                    aria-describedby="ciudadnote"
                    onFocus={() => setFocusCiudad(true)}
                    onBlur={() => setFocusCiudad(false)}
                />
                <p id="ciudadnote" className={focusCiudad && ciudad && !validCiudad ? "instructions" : "offscreen"}>
                    <FontAwesomeIcon icon={faInfoCircle} />
                    Debe introducir una ciudad válida<br />
                    Solo se permiten letras y espacios.
                    La primera letra debe estar en mayúscula
                </p>

                <label htmlFor='provincia' className='form-label'>Provincia
                    <span className={validProvincia ? "valid" : "hide"}>
                        <FontAwesomeIcon icon={faCheck} />
                    </span>
                    <span className={validProvincia || !provincia ? "hide" : "invalid"}>
                        <FontAwesomeIcon icon={faTimes} />
                    </span>
                </label>
                <input type="text" className='form-control'
                    placeholder="Ingrese la provincia"
                    value={provincia}
                    onChange={(u) => setProvincia(u.target.value)}
                    id="provinciaUsuario" ref={provinciaRef} autoComplete="off"
                    required
                    aria-invalid={validProvincia ? "false" : "true"}
                    aria-describedby="provnote"
                    name='provincia'
                    onFocus={() => setFocusProvincia(true)}
                    onBlur={() => setFocusProvincia(false)}
                />
                <p id="provnote" className={focusProvincia && provincia && !validProvincia ? "instructions" : "offscreen"}>
                    <FontAwesomeIcon icon={faInfoCircle} />
                    Debe introducir una provincia válida,<br />
                    No puede contener números
                </p>

                <label htmlFor='codigo_postal' className='form-label'>Codigo Postal
                    <span className={validCodigo ? "valid" : "hide"}>
                        <FontAwesomeIcon icon={faCheck} />
                    </span>
                    <span className={validCodigo || !codigo_postal ? "hide" : "invalid"}>
                        <FontAwesomeIcon icon={faTimes} />
                    </span>
                </label>
                <input type="text" className='form-control'
                    placeholder="Ingrese el codigo postal"
                    value={codigo_postal}
                    name='codigo_postal'
                    onChange={(u) => setCodigoPostal(u.target.value)}
                    id="codpostUsuario" ref={codigoPostalRef} autoComplete="off"
                    required
                    aria-invalid={validCodigo ? "false" : "true"}
                    aria-describedby="codpostnote"
                    onFocus={() => setFocusCodigo(true)}
                    onBlur={() => setFocusCodigo(false)}
                />
                <p id="codpostnote" className={focusCodigo && codigo_postal && !validCodigo ? "instructions" : "offscreen"}>
                    <FontAwesomeIcon icon={faInfoCircle} />
                    Debe introducir un Código postal válida,<br />
                    de 1 a 5 numeros
                </p>

                <label htmlFor='mensaje' className='form-label'>Comentario para el transportista:
                    <span className={validMensaje ? "valid" : "hide"}>
                        <FontAwesomeIcon icon={faCheck} />
                    </span>
                    <span className={validMensaje || !mensaje ? "hide" : "invalid"}>
                        <FontAwesomeIcon icon={faTimes} />
                    </span></label>

                <textarea rows={6} cols={18} type="text" className='form-control'
                    placeholder="Ingrese el mensaje"
                    value={mensaje}
                    onChange={(u) => setMensaje(u.target.value)}
                    id="mensajeUsuario" ref={mensajeRef} autoComplete="off"
                    required
                    aria-invalid={validMensaje ? "false" : "true"} name='mensaje_mail' />

                <button className='btn btn-success mt3'>Finalizar Compra</button>
            </form>
        </div>
    )

}

export default FinalizarCompra