import React from 'react'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'

function Premontaje() {
    return (
        <div className='container parent-container'>
            <button type="button" class="btn btn-success my-link btn-sm"><Link to="/Servicios"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-return-left" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z" />
            </svg></Link></button>
            <div class="row justify-content-md-center parent-container">
                <div class="col-sm-8">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">PREMONTAJE QUE AHORRA TIEMPO</h5>
                            <p class="card-text">Aseguramos la funcionalidad de los componentes individuales y aseguramos el dimensionamiento más eficiente de los ensamblajes preensamblados.</p>
                            <ul className='milista'>
                                <li>Sin sobredimensionamiento</li>
                                <li>Fiabilidad de la planificación a través de la entrega adaptada a las secuencias de construcción</li>
                                <li>Reducción del tiempo de montaje</li>
                                <li>Identificación clara y embalaje separado de las unidades</li>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Premontaje