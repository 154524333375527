import React from 'react'
import { Nav, Navbar, NavbarBrand, NavDropdown } from 'react-bootstrap'
import logo from './img/logo.jpg';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'


function Menu() {

    var sesion = sessionStorage.getItem("nombre");

    if (sesion == null) {
        return (
            <Navbar bg="green" variant="dark" sticky='top' expand='lg' >
                <NavbarBrand>
                    <Nav.Link><Link to="/"><img src={logo} height='85px' width='205px' /></Link></Nav.Link>
                </NavbarBrand>
                <Navbar.Toggle />
                <Navbar.Collapse>
                    <Nav activeKey="/Usuarios" >
                        <Nav.Link className='my-link-menu'><Link to="/Productos">Productos</Link></Nav.Link>
                        <NavDropdown title="Productos">
                            <NavDropdown.Item className='dropdown-item' href="/#/Abrazaderastuberias" style={{ backgroundColor: '#387e59' }} >Abrazaderas de tuberia</NavDropdown.Item>
                            <NavDropdown.Item className='dropdown-item' href="/#/Accesoriosmontajes" style={{ backgroundColor: '#387e59' }} >Accesorios de montaje</NavDropdown.Item>
                            <NavDropdown.Item className='dropdown-item' href="/#/Sistemasmontajeventilacion" style={{ backgroundColor: '#387e59' }} >Sistemas de montaje para ventilación</NavDropdown.Item>
                            <NavDropdown.Item className='dropdown-item' href="/#/Carrilesmontaje" style={{ backgroundColor: '#387e59' }} >Carriles de montaje</NavDropdown.Item>
                            <NavDropdown.Item className='dropdown-item' href="/#/Aisladoresruido" style={{ backgroundColor: '#387e59' }} >Aisladores de resorte</NavDropdown.Item>
                            <NavDropdown.Item className='dropdown-item' href="/#/Abrazaderasaislamiento" style={{ backgroundColor: '#387e59' }} >Abrazaderas de tubería aisladas</NavDropdown.Item>
                            <NavDropdown.Item className='dropdown-item' href="/#/Accesoriostrabajo" style={{ backgroundColor: '#387e59' }} >Accesorios de trabajo, heramientas...</NavDropdown.Item>
                            <NavDropdown.Item className='dropdown-item' href="/#/Sprinklers" style={{ backgroundColor: '#387e59' }} >Sistemas contra incendios</NavDropdown.Item>
                            <NavDropdown.Item className='dropdown-item' href="/#/Puntosfijos" style={{ backgroundColor: '#387e59' }} >Puntos fijos</NavDropdown.Item>
                            <NavDropdown.Item className='dropdown-item' href="/#/Elementosdeslizantes" style={{ backgroundColor: '#387e59' }} >Elementos deslizantes</NavDropdown.Item>
                            <NavDropdown.Item className='dropdown-item' href="/#/AceroInoxidable" style={{ backgroundColor: '#387e59' }} >Acero inoxidable</NavDropdown.Item>
                            <NavDropdown.Item className='dropdown-item' href="/#/Anclajes" style={{ backgroundColor: '#387e59' }} >Anclajes</NavDropdown.Item>
                        </NavDropdown>

                        <Nav.Link className='my-link-menu'><Link to="/Servicios">Servicios</Link></Nav.Link>
                        <Nav.Link className='my-link-menu'><Link to="/SobreNosotros">Sobre nosotros</Link></Nav.Link>
                        <Nav.Link className='my-link-menu'><Link to="/Contacto">Contacto</Link></Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                <Nav>
                    <Nav.Link><Link to="/Login"><i className="bi bi-person fs-1 text-white text-right"></i></Link></Nav.Link>
                    <Nav.Link><Link to="/Carrito"><i className="bi bi-cart3 fs-1 text-white text-right"></i></Link></Nav.Link>
                </Nav>
            </Navbar>
        )
    } else if (sesion != null && sesion != "admin") {
        return (
            <Navbar bg="green" variant="dark" sticky='top' expand='lg' >
                <NavbarBrand>
                    <Nav.Link><Link to="/"><img src={logo} height='85px' width='205px' /></Link></Nav.Link>
                </NavbarBrand>
                <Navbar.Toggle />
                <Navbar.Collapse>
                    <Nav activeKey="/Usuarios" >
                        <Nav.Link className='my-link-menu'><Link to="/Productos">Productos</Link></Nav.Link>
                        <NavDropdown title="Productos" >
                        <NavDropdown.Item className='dropdown-item' href="/#/Abrazaderastuberias" style={{ backgroundColor: '#387e59' }} >Abrazaderas de tuberia</NavDropdown.Item>
                            <NavDropdown.Item className='dropdown-item' href="/#/Accesoriosmontajes" style={{ backgroundColor: '#387e59' }} >Accesorios de montaje</NavDropdown.Item>
                            <NavDropdown.Item className='dropdown-item' href="/#/Sistemasmontajeventilacion" style={{ backgroundColor: '#387e59' }} >Sistemas de montaje para ventilación</NavDropdown.Item>
                            <NavDropdown.Item className='dropdown-item' href="/#/Carrilesmontaje" style={{ backgroundColor: '#387e59' }} >Carriles de montaje</NavDropdown.Item>
                            <NavDropdown.Item className='dropdown-item' href="/#/Aisladoresruido" style={{ backgroundColor: '#387e59' }} >Aisladores de resorte</NavDropdown.Item>
                            <NavDropdown.Item className='dropdown-item' href="/#/Abrazaderasaislamiento" style={{ backgroundColor: '#387e59' }} >Abrazaderas de tubería aisladas</NavDropdown.Item>
                            <NavDropdown.Item className='dropdown-item' href="/#/Accesoriostrabajo" style={{ backgroundColor: '#387e59' }} >Accesorios de trabajo, heramientas...</NavDropdown.Item>
                            <NavDropdown.Item className='dropdown-item' href="/#/Sprinklers" style={{ backgroundColor: '#387e59' }} >Sistemas contra incendios</NavDropdown.Item>
                            <NavDropdown.Item className='dropdown-item' href="/#/Puntosfijos" style={{ backgroundColor: '#387e59' }} >Puntos fijos</NavDropdown.Item>
                            <NavDropdown.Item className='dropdown-item' href="/#/Elementosdeslizantes" style={{ backgroundColor: '#387e59' }} >Elementos deslizantes</NavDropdown.Item>
                            <NavDropdown.Item className='dropdown-item' href="/#/AceroInoxidable" style={{ backgroundColor: '#387e59' }} >Acero inoxidable</NavDropdown.Item>
                            <NavDropdown.Item className='dropdown-item' href="/#/Anclajes" style={{ backgroundColor: '#387e59' }} >Anclajes</NavDropdown.Item>
                        </NavDropdown>

                        <Nav.Link className='my-link-menu'><Link to="/Servicios">Servicios</Link></Nav.Link>
                        <Nav.Link className='my-link-menu'><Link to="/SobreNosotros">Sobre nosotros</Link></Nav.Link>
                        <Nav.Link className='my-link-menu'><Link to="/Contacto">Contacto</Link></Nav.Link>
                        <p>Hola {sesion}</p>
                    </Nav>
                </Navbar.Collapse>
                <Nav>
                    <Nav.Link className='my-link-menu'><Link to="/LogOut">Cerrar sesión</Link></Nav.Link>
                    <Nav.Link><Link to="/Login"><i className="bi bi-person fs-1 text-white text-right"></i></Link></Nav.Link>
                    <Nav.Link><Link to="/Carrito"><i className="bi bi-cart3 fs-1 text-white text-right"></i></Link></Nav.Link>
                </Nav>
            </Navbar>
        )
    } else if (sesion == "admin") {
        return (
            <Navbar bg="green" variant="dark" sticky='top' expand='lg' >
                <NavbarBrand>
                    <Nav.Link><Link to="/"><img src={logo} height='85px' width='205px' /></Link></Nav.Link>
                </NavbarBrand>
                <Navbar.Toggle />
                <Navbar.Collapse>
                    <Nav activeKey="/Usuarios" >
                        <Nav.Link className='my-link-menu'><Link to="/Productos">Productos</Link></Nav.Link>
                        <NavDropdown title="Productos" >
                        <NavDropdown.Item className='dropdown-item' href="/#/Abrazaderastuberias" style={{ backgroundColor: '#387e59' }} >Abrazaderas de tuberia</NavDropdown.Item>
                            <NavDropdown.Item className='dropdown-item' href="/#/Accesoriosmontajes" style={{ backgroundColor: '#387e59' }} >Accesorios de montaje</NavDropdown.Item>
                            <NavDropdown.Item className='dropdown-item' href="/#/Sistemasmontajeventilacion" style={{ backgroundColor: '#387e59' }} >Sistemas de montaje para ventilación</NavDropdown.Item>
                            <NavDropdown.Item className='dropdown-item' href="/#/Carrilesmontaje" style={{ backgroundColor: '#387e59' }} >Carriles de montaje</NavDropdown.Item>
                            <NavDropdown.Item className='dropdown-item' href="/#/Aisladoresruido" style={{ backgroundColor: '#387e59' }} >Aisladores de resorte</NavDropdown.Item>
                            <NavDropdown.Item className='dropdown-item' href="/#/Abrazaderasaislamiento" style={{ backgroundColor: '#387e59' }} >Abrazaderas de tubería aisladas</NavDropdown.Item>
                            <NavDropdown.Item className='dropdown-item' href="/#/Accesoriostrabajo" style={{ backgroundColor: '#387e59' }} >Accesorios de trabajo, heramientas...</NavDropdown.Item>
                            <NavDropdown.Item className='dropdown-item' href="/#/Sprinklers" style={{ backgroundColor: '#387e59' }} >Sistemas contra incendios</NavDropdown.Item>
                            <NavDropdown.Item className='dropdown-item' href="/#/Puntosfijos" style={{ backgroundColor: '#387e59' }} >Puntos fijos</NavDropdown.Item>
                            <NavDropdown.Item className='dropdown-item' href="/#/Elementosdeslizantes" style={{ backgroundColor: '#387e59' }} >Elementos deslizantes</NavDropdown.Item>
                            <NavDropdown.Item className='dropdown-item' href="/#/AceroInoxidable" style={{ backgroundColor: '#387e59' }} >Acero inoxidable</NavDropdown.Item>
                            <NavDropdown.Item className='dropdown-item' href="/#/Anclajes" style={{ backgroundColor: '#387e59' }} >Anclajes</NavDropdown.Item>
                        </NavDropdown>

                        <Nav.Link className='my-link-menu'><Link to="/Servicios">Servicios</Link></Nav.Link>
                        <Nav.Link className='my-link-menu'><Link to="/SobreNosotros">Sobre nosotros</Link></Nav.Link>
                        <Nav.Link className='my-link-menu'><Link to="/Contacto">Contacto</Link></Nav.Link>
                        <Nav.Link className='my-link-menu'><Link to="/Trabajadores">Trabajadores</Link></Nav.Link>

                        {/* <Nav.Link as={Link} to={"/Usuarios"}>Usuarios</Nav.Link> */}

                        <p>Hola {sesion}</p>
                    </Nav>
                </Navbar.Collapse>
                <Nav>
                    <Nav.Link className='my-link-menu'><Link to="/LogOut">Cerrar sesión</Link></Nav.Link>
                    <Nav.Link><Link to="/Login"><i className="bi bi-person fs-1 text-white text-right"></i></Link></Nav.Link>
                    <Nav.Link><Link to="/Carrito"><i className="bi bi-cart3 fs-1 text-white text-right"></i></Link></Nav.Link>
                </Nav>
            </Navbar>
        )
    }
}
export default Menu
