import React, { useState, useEffect } from 'react';
import ListaProductos from './listar/ListaProductos';
import FormProductos from './formularios/FormProductos';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { rutaMaquina } from './Rutas';
import Swal from 'sweetalert2';
function Productos() {
  const [productos, setProductos] = useState([])
  const [editadoProducto, setEditadoProducto] = useState(null)
  const [listaCarrito, setListaCarrito] = useState(JSON.parse(localStorage.getItem("listaCarrito")) || []);

  useEffect(() => {
    fetch(rutaMaquina + `/herfontsistemas-back/productos`, {
      'method': 'GET',
      headers: { "Content-type": "application/json" }
    }).then(
      res => res.json()
    ).then(
      res => {
        setProductos(res)
      }
    ).catch(error => console.log(error))
  }, [])

  const editarProducto = (producto) => {
    setEditadoProducto(producto)
    
  }

  const datoModificado = (producto) => {
    const nuevo_producto = productos.map(mi_producto => {
      if (mi_producto.id === producto.id) {
        Swal.fire({
          title: "¡ÉXITO!",
          text: "Se ha modificado un producto nuevo",
          icon: "suscess",
        })
        return producto
      } else {
        Swal.fire({
          title: "¡ÉXITO!",
          text: "Se ha modificado un producto nuevo",
          icon: "suscess",
        })
        return mi_producto
      }
    })
    setProductos(nuevo_producto)
  }

  const abrirForm = () => {
    setEditadoProducto({ nom_producto: '', descripcion: '', cantidad: '', imagen: '', precio: '' })
  }

  const productoInsertado = (producto) => {
    const nuevo_producto = [...productos, producto]
    setProductos(nuevo_producto)
  }

  const borrarProducto = (producto) => {
    const nuevo_producto = productos.filter(miproducto => {
      if (miproducto.id === producto.id) {
        return false;
      }
      return true;
    })
    setProductos(nuevo_producto)
  }
  const anadirProductoCesta = (producto) => {
    const encontrarProductos = productos.find((x) => x.id === producto.id);
    var nuevaCantidad = parseInt(cantidad)
    if (expresion_cantidad.test(nuevaCantidad)) {
      if (encontrarProductos) {
        console.log(encontrarProductos.cantidad)
        console.log(nuevaCantidad)
        if (encontrarProductos.cantidad < nuevaCantidad) {
          alert('no hay productos suficientes')
        } else {
          var posicion = listaCarrito.findIndex((x) => x.id == producto.id);
          if (posicion != -1) {
            listaCarrito[posicion].cantidad = nuevaCantidad;
            alert('producto añadido a la cesta')
          } else {
            var productoComprado = {
              'id': encontrarProductos.id,
              'nombre': encontrarProductos.nom_producto,
              'cantidad': nuevaCantidad,
              'precio': encontrarProductos.precio
            }
            setListaCarrito([...listaCarrito, productoComprado])
            localStorage.setItem("listaCarrito", JSON.stringify([...listaCarrito, productoComprado]));
            alert('producto añadido a la cesta')
          }
        }

      }
    }
  }

  const expresion_cantidad = /^[0-9]+$/;
  const [cantidad, setCantidad] = useState('')
  const [validCantidad, setValidCantidad] = useState(false)

  useEffect(() => {
    setCantidad(productos.cantidad)
  }, [productos])
  useEffect(() => {
    const result = expresion_cantidad.test(cantidad);
    setValidCantidad(result)
  });

  var sesion = sessionStorage.getItem("nombre");
  if (sesion != null && sesion != "admin") {
    return (
      <div className='container parent-container'>
        <div>
        <h1>Productos</h1>
          {productos.map(producto => {
            return (
              <div>
                <h3>Nombre: {producto.nom_producto}</h3>
                <img className="small" src={rutaMaquina + '/herfontsistemas-back/static/img/' + producto.imagen} alt={producto.nom_producto} style={{ width: '200px' }}/>
                <div>Precio: {producto.precio}€</div>
                <div>Cantidad: <input id='productoCantidad' type="text" min='1' aria-invalid={validCantidad ? "false" : "true"} onChange={(u) => setCantidad(u.target.value)}></input></div>
                <div>
                  <button className='btn btn-success' onClick={() => anadirProductoCesta(producto)}>Añadir al carrito</button>
                </div>
                <br></br>
                <br></br>
              </div>
            )
          }
          )
          }
        </div>
      </div>
    )
  } else if (sesion == null) {
    return (
      <div className='container parent-container'>
        <div>
          <h1>Productos</h1>
          {productos.map(producto => {
            return (
              <div>
                <h3>Nombre: {producto.nom_producto}</h3>
                <img className="small" src={rutaMaquina + '/herfontsistemas-back/static/img/' + producto.imagen} alt={producto.nom_producto} style={{ width: '200px' }}/>
                <div>Precio: {producto.precio}€</div>
                <div>Cantidad: <input id='productoCantidad' type="text" min='1' aria-invalid={validCantidad ? "false" : "true"} onChange={(u) => setCantidad(u.target.value)}></input></div>
  
                <p id="cantidadValid" className={cantidad && !validCantidad ? "instructions" : "offscreen"}>
                  <FontAwesomeIcon icon={faInfoCircle} />
                  Solo se permiten números
                </p>
                <div>
                  <button className='btn btn-success' onClick={() => anadirProductoCesta(producto)}>Añadir al carrito</button>
                </div>
                <br></br>
                <br></br>
              </div>
            )
          }
          )
          }
        </div>
      </div>
    )
  } else if (sesion == "admin") {
    return (
      <div className='container parent-container'>
        <div className='col'>
          <button
            className='btn btn-success'
            onClick={abrirForm}
          >Insertar Productos</button>
        </div>
        <br></br>
                <br></br>
        <ListaProductos productos={productos} editarProducto={editarProducto} borrarProducto={borrarProducto} />
        {editadoProducto ? <FormProductos producto={editadoProducto} datoModificado={datoModificado} productoInsertado={productoInsertado} /> : null}
      </div>
    )
  }
}


export default Productos