import React from 'react'
import abrazaderaaislamiento from "../img/abrazaderaaislamiento.JPG"
function Abrazaderasaislamiento() {
    return (
        <div className='container mt-4 parent-container+'>
            < h2>ABRAZADERAS DE TUBERÍAS AISLADAS</h2>
            <p><img src={abrazaderaaislamiento}></img></p>
        </div>
    )
}

export default Abrazaderasaislamiento
