import React from 'react'
import elementosdeslizantes from "../img/elementosdeslizantes.JPG"
function Elementosdeslizantes() {
    return (
        <div className='container mt-4 parent-container'>
            < h2>ELEMENTOS DESLIZANTES</h2>
            <p><img src={elementosdeslizantes}></img></p>
        </div>
    )
}

export default Elementosdeslizantes
