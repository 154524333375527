import React, { useRef, useState, useEffect } from 'react';
import APIService from '../APIService'
import { rutaMaquina } from '../Rutas';
function ListaProductos(props) {

  const editarProducto = (producto) => {
    console.log(producto)
    props.editarProducto(producto)
  }

  const borrarProducto = (producto) => {
    APIService.BorrarProducto(producto.id)
      .then(() => props.borrarProducto(producto))
  }

  return (
    <div>
      <table className='table table-striped table-hover mt-5 shadow-lg'>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Descripcion</th>
            <th>Cantidad</th>
            <th>Imagen</th>
            <th>Precio</th>
          </tr>
        </thead>
        {props.productos.map(producto => {
          return (
            <tbody>
              <tr>
                <td key={producto.id}>{producto.nom_producto}</td>
                <td>{producto.descripcion}</td>
                <td>{producto.cantidad}</td>
                <td><img src={rutaMaquina + '/herfontsistemas-back/static/img/' + producto.imagen} alt="error de carga" width={"80px"}></img></td>
                <td>{producto.precio}</td>
                <td>
                  <button className="btn btn-primary"
                    onClick={() => editarProducto(producto)}
                  >Modificar</button>

                  <button className="btn btn-danger"
                    onClick={() => borrarProducto(producto)}
                  >Eliminar</button>
                </td>
              </tr>
            </tbody>
          )
        })}
      </table>

    </div>
  )
}

export default ListaProductos;
