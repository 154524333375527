import React from 'react'
import carriles from "../img/carriles.JPG"
import carriles2 from "../img/carriles2.JPG"

function Carrilesmontaje() {
  return (
    <div className='container mt-4 parent-container'>
      <h2>CARRILES DE MONTAJE</h2>
      <p><img src={carriles}></img></p>
      <br></br><br></br>
      <p><img src={carriles2}></img></p>
      <br></br>
    </div>
  )
}

export default Carrilesmontaje
