import React from 'react'
import accesoriosmontaje from "../img/accesoriosmontaje.JPG"
function Accesoriosmontajes() {
    return (
        <div className='container mt-4 parent-container'>
            < h2>ACCESORIOS DE MONTAJE</h2>
            <p><img src={accesoriosmontaje}></img></p>
        </div>
    )
}

export default Accesoriosmontajes