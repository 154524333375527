import React from 'react'
import abrazaderas from "../img/abrazaderas.JPG"
function Abrazaderastuberias() {
    return (
        <div className='container mt-4 parent-container'>
            < h2>ABRAZADERAS DE TUBERÍAS</h2>
            <p><img src={abrazaderas}></img></p>
        </div>
    )
}

export default Abrazaderastuberias
