import React, { useState, useEffect } from 'react';
import FormRegistro from '../formularios/FormRegistro';
import FormLogin from '../formularios/FormLogin';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css'
import Swal from 'sweetalert2';
import { rutaMaquina } from '../Rutas';

function ConfigRegistroLogin() {
  const [usuarios, setUsuario] = useState([])
  const [editadoUsuario, setEditadoUsuario] = useState(null)
  const [logueadoUsuario, setLogueadoUsuario] = useState(null)

  useEffect(() => {
    fetch(rutaMaquina+'/herfontsistemas-back/login', {
      'method': 'GET',
      headers: { "Content-type": "application/json" }

    }).then(
      res => res.json()
    ).then(
      res => {
        setUsuario(res)
      }
    ).catch(error => console.log(error))
  }, [])

  
  const editarUsuario = (usuario) => {
    setEditadoUsuario(usuario)
  }
  const loguearUsuario = (usuario) => {
    setLogueadoUsuario(usuario)
  }

  const abrirForm = () => {
    setEditadoUsuario({ nombre: '', apellidos: '', correo: '', telefono: '', contrasena: '', direccion: '', ciudad: '', provincia: '', codigo_postal: '', descuento: '' })
  }
  const abrirFormLogin = () => {
    setLogueadoUsuario({ correo: '', contrasena: '' })
  }

  async function usuarioInsertado(usuario) {
    console.log(usuario)
    if (usuario.errorDuplicado) {
      Swal.fire({
        title: "¡Error!",
        text: "El correo electrónico insertado ya existe.",
        icon: "error",
      })
    } else {
      const nuevo_usuario = [...usuarios, usuario]
      setUsuario(nuevo_usuario)
      const { value: accept } = await Swal.fire({
        title: "¡ÉXITO!",
        text: "Usuario añadido correctamente",
        icon: "success",
      })
      if (accept) {
        window.location.reload(true);
      }
    }
  }

  async function usuarioLogueado(usuario) {
    console.log("entra")
    console.log(usuario)
    console.log(usuario.nombre)
    if (usuario.errorLog) {
      Swal.fire({
        title: "¡Error!",
        text: usuario.errorLog,
        icon: "error",
      })
    } else {
      setLogueadoUsuario(true)
      sessionStorage.setItem("nombre", usuario.nombre)
      var sesion = sessionStorage.getItem("nombre")
      const { value: accept } = await Swal.fire({
        title: "¡Nos encanta tenerte de vuelta!",
        text: sesion + " acaba de iniciar sesión.",
        icon: "success",
      })
      if (accept) {
        window.location.reload(true);
      }
    }
  }



  return (
    <div className='container mt-4 parent-container'>
      <div className='card text-center'>

        <h1>Está a un paso de autenticarse</h1>
        <p>¿Ya está registrado?</p>
        <button className='btn btn-success boton-login mx-auto'
          onClick={abrirFormLogin}>Iniciar Sesión</button>
        <p>¿No está registrado?</p>
        <button
          className='btn btn-success boton-login  mx-auto'
          onClick={abrirForm}
        >Registrarse</button>
      </div>


      {editadoUsuario ? <FormRegistro usuario={editadoUsuario} usuarioInsertado={usuarioInsertado} /> : null}
      {logueadoUsuario ? <FormLogin usuario={logueadoUsuario} usuarioLogueado={usuarioLogueado} /> : null}
    </div>
  )
}

export default ConfigRegistroLogin